/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Amplify } from 'aws-amplify';
import * as React from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import SidebarWrapper from '../components/SidebarWrapper';

// @ts-ignore
import config from '../aws-exports';
let isLocalhost;
let isFeat;
let devRedirect;
if (typeof window !== 'undefined') {
  isLocalhost = !!(window.location.hostname === 'localhost');
  isFeat = !!(window.location.hostname === 'feature.d2b2yr0jqmp0n5.amplifyapp.com');
  console.log('isFeat', isFeat, window.location.hostname);
  devRedirect = isLocalhost ? 'http://localhost:8000/search/' : undefined;
  devRedirect = isFeat ? 'https://feature.d2b2yr0jqmp0n5.amplifyapp.com/search/' : devRedirect;
}

// use correct URI in the right env
const updatedAwsConfig = {
  ...config,
  oauth: {
    ...config.oauth,
    redirectSignIn: devRedirect ? devRedirect : config.oauth.redirectSignIn,
    redirectSignOut: config.oauth.redirectSignOut // fix this to be dynamic
  }
};

Amplify.configure(updatedAwsConfig);

export default function App() {
  return (
    <SidebarWrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}>
        <Authenticator socialProviders={['google']}>
          {({ signOut, user }) => (
            <main>
              <h3>Hello {user?.username}</h3>
              <button onClick={signOut}>Sign out</button>
            </main>
          )}
        </Authenticator>
      </div>
    </SidebarWrapper>
  );
}
